import React from 'react';
import PropTypes from 'prop-types';

import StaticPage from '../components/staticpage/StaticPage';
import SEO from '../components/SEO';

const NotFoundPage = ({ location }) => {
    const pagepathname = location.pathname;

    return (
        <StaticPage title="Page not found">
            <SEO
                title="Page not found"
                pagepathname={pagepathname}
            />

            <p>
                Oh dear! That page couldn&apos;t be found. Please press back in your browser, or try again in a while.
            </p>
        </StaticPage>
    );
};

export default NotFoundPage;

NotFoundPage.propTypes = {
    location: PropTypes.object,
};
