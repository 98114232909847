import React from 'react';
import PropTypes from 'prop-types';

export default function StaticPage ({ children, title }) {
    return (
        <>
            <h1 className="u-title">
                {title}
            </h1>

            {children}
        </>
    );
}

StaticPage.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string.isRequired,
};
